import Vue from "vue";
import Router from "vue-router";

const accueil = () =>
  import(/* webpackChunkName: "public" */ "../components/accueil.vue");
const test = () =>
  import(/* webpackChunkName: "public" */ "../components/test.vue");
const Login = () =>
  import(/* webpackChunkName: "foo" */ "../components/Login.vue");
const Register = () =>
  import(/* webpackChunkName: "foo" */ "../components/Register.vue");
const Dashboard = () =>
  import(/* webpackChunkName: "admin" */ "../components/Dashboard.vue");

Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      name: "accueil",
      component: accueil,
    },
    {
      path: "/test",
      name: "test",
      component: test,
    },
    {
      path: "/login",
      name: "login",
      component: Login,
    },
    {
      path: "/register",
      name: "Register",
      component: Register,
    },
    {
      path: "/dashboard",
      name: "Dashboard",
      component: Dashboard,
    },
  ],
});

export default router;
