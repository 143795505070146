import Vue from "vue";
import App from "./App.vue";
import firebase from "firebase/app";
import "firebase/auth";
import router from "@/router";
import vuetify from "./plugins/vuetify";
import store from "@/store";

Vue.config.productionTip = false;

const firebaseConfig = {
  apiKey: "AIzaSyAquarqSvodgS1Mb_IJ6YC-I4Tule_zYyQ",
  authDomain: "vega-d7536.firebaseapp.com",
  databaseURL:
    "https://vega-d7536-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "vega-d7536",
  storageBucket: "vega-d7536.appspot.com",
  messagingSenderId: "46957929983",
  appId: "1:46957929983:web:5b002827eadf01b927b3ca",
};

firebase.initializeApp(firebaseConfig);

firebase.auth().onAuthStateChanged((user) => {
  store.dispatch("fetchUser", user);
});

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
