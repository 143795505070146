<template>
  <v-app>
    <v-app-bar absolute color="white" app outlined :elevation="1">
      <v-toolbar-title>Luxodeon</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn href="/" text>
        <span class="mr-2">
          <v-icon color="blue-grey darken-2"> mdi-home </v-icon></span
        >
      </v-btn>
      <v-btn href="/register" text>
        <span class="mr-2">
          <v-icon color="blue-grey darken-2"> mdi-account-plus </v-icon></span
        >
      </v-btn>
      <v-btn href="/dashboard" text>
        <span class="mr-2">
          <v-icon color="blue-grey darken-2"> mdi-view-dashboard </v-icon></span
        >
      </v-btn>
      <v-btn v-if="!user.loggedIn" href="/login" text>
        <span class="mr-2">
          <v-icon color="blue-grey darken-2"> mdi-login </v-icon></span
        >
      </v-btn>
      <v-btn v-if="user.loggedIn" @click="signOut" text>
        <span class="mr-2">Log-Out</span>
      </v-btn>
      <span v-if="user.loggedIn">{{ user.data.displayName }}</span>
    </v-app-bar>

    <v-main>
      <v-container>
        <router-view />
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import firebase from "firebase/app";
import "firebase/auth";
import { mapGetters } from "vuex";

export default {
  name: "App",

  computed: {
    ...mapGetters({
      // map `this.user` to `this.$store.getters.user`
      user: "user",
    }),
  },
  methods: {
    signOut() {
      firebase
        .auth()
        .signOut()
        .then(() => {
          this.$router.push("/");
        });
    },
  },
};
</script>
